<template>
  <div class="apply person">
    <div class="add">
      <router-link :to="{
        path: `/lesson/completeInfo`,
        query: {path: '/lesson/applyDetail', id: id, sid: sid}
      }">      
        <i class="jym-tianjia1"></i>添加上课人
      </router-link>
    </div>

    <div class="bar">近期使用<span class="fr">左滑操作</span></div>
    <div class="list" v-for="(item, index) in personList" :key="index">
      <div v-if="item.isdefault == 1">
        <span class="self">本人（禁止操作）</span>
        <mt-checklist v-model="itemRadio[index]" :options="[item.name + ' ' + item.tel]"></mt-checklist>
      </div>
      <div v-else>
        <mt-cell-swipe
          :right="[
            {            
              content: '编辑',
              style: { background: '#333', color: '#fff', padding: '0 .5rem' },
              handler: () => edit(item.cert_id),
            },
            {            
              content: '删除',
              style: { background: '#f67a38', color: '#fff', padding: '0 .5rem' },
              handler: () => deleteSection(item.cert_id)
            }
          ]"
        >
          <mt-checklist v-model="itemRadio[index]" :options="[item.name + ' ' + item.tel]"></mt-checklist>
        </mt-cell-swipe>   
      </div>
     
    </div>

    <div class="btn-bottom">
      <button class="btn-black" @click="submit">确认</button>
    </div>
  </div>
</template>

<script>
import { lessonCert, certDel } from "@api/public";
export default {
  name: "person-list",
  data() {
    return {
      id: "",
      sid: "",
      cert_ids: [],
      personList: {},
      session_Data: {},
      itemRadio: [],
      select_Person: [],
      is_self: false,
      id_self: '',
    };
  },
  created() {
    if(this.$route.query.id) {
      this.id = this.$route.query.id;
      this.sid = this.$route.query.sid;
      this.cert_ids = this.$route.query.cert_ids;
    }
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      let that = this;

      lessonCert().then(res => {
        that.$set(that, 'personList', res.data);
        res.data.forEach((item, index) => {
          that.itemRadio.push(false);
          // 获取本人id
          if(item.isdefault == 1) {
            that.id_self = item.cert_id
          }
          // 默认勾选已选中的人员
          if(that.cert_ids) {
            if(that.cert_ids.includes(item.cert_id)) {
              that.itemRadio[index] = true;
            }
          }
        })
      })
    },

    submit() {
      let that = this,
        personList = that.personList;

      if(that.itemRadio.includes(true)) {
        that.select_Person = that.itemRadio.map((item, i) => {
          if(item) {
            return {
              cert_id: personList[i].cert_id,
              name: personList[i].name,
              tel: personList[i].tel
            }
          }
        }).filter(item => item);
        // 判断本人有没有被勾选上
        that.is_self =  that.select_Person.some(item => item.cert_id == that.id_self)
        
        that.$router.push({
          path: '/lesson/applyDetail',
          query: {id: that.id, sid: that.sid, is_self: that.is_self, select_Person: JSON.stringify(that.select_Person)}
        })
      } else {
        that.$dialog.error('至少添加一位上课人！');
      }

    },

    // 编辑
    edit(cert_id) {
      this.$router.push({
        path: '/lesson/completeInfo',
        query: {cert_id: cert_id}
      })
    },
    // 删除
    deleteSection(cert_id) {
      certDel(cert_id).then(() => {
        this.$dialog.success('删除成功!');
        this.$router.go(0);
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.person {
  .add {
    text-align: center;
    line-height: 0.8rem;
    height: 0.8rem;
    i {
      font-weight: 700;
      margin: 0 0.1rem;
    }
  }
  .bar {
    height: 0.6rem;
    line-height: 0.6rem;
    background: #f5f5f5;
    padding: 0 0.3rem;
    font-size: .24rem;
  }
  .list {
    padding-left: .3rem;   
  }
}
</style>
